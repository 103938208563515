@media screen and (min-width: 375px) and (max-width: 767px) {
  .container {
    background-color: rgba(205, 44, 125, 0.819);
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  nav {
    margin-top: 10px;
    align-self: flex-start;
  }

  .nav a {
    text-decoration: none;
  }

  .img-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
  }

  .detail-header {
    text-align: center;
    color: aliceblue;
    text-decoration: underline;
    text-underline-offset: 10px;
  }

  .image-box {
    width: 9rem;
    text-align: center;
    color: aliceblue;
    margin: 20px;
  }

  .cat-img {
    width: 9rem;
    height: 9rem;
    border: 4px solid rgba(255, 105, 180, 0.819);
    border-radius: 15px;
    opacity: 0.8;
  }

  .grid-container {
    width: 96vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    padding: 10px;
    text-align: center;
  }

  .feature {
    width: auto;
    font-size: 16px;
    color: #fff;
    margin: 0;
    padding: 5px;
  }

  .BreedDets {
    border: 1px solid rgb(243, 150, 196);
    color: rgba(240, 248, 255, 0.844);
    margin-block-start: 0;
    margin-block-end: 0;
    padding: 5px;
  }

  .Back-Button {
    color: aliceblue;
    margin-left: 10px;
    font-size: 1.3rem;
    border: 1px solid #fff;
    border-radius: 20%;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    font-size: 12vw;
    height: 100vh;
    background-color: rgba(205, 44, 125, 0.819);
    margin: 0;
  }
}
