@media only screen and (max-width: 767px) {
  .home-container {
    background-color: rgba(205, 44, 125, 0.819);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header {
    display: flex;
  }

  .header-img {
    width: 100%;
    border: 3px solid aliceblue;
    opacity: 0.8;
  }

  .headline {
    text-decoration: underline;
    text-underline-offset: 10px;
    color: aliceblue;
  }

  .search-bar-wrapper {
    width: 100%;
    margin: 0 auto;
  }

  input[type="text"] {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .table-wrapper {
    margin-top: 10px;
  }

  .Breeds {
    color: purple;
    margin: 0;
    padding: 0;
    align-self: flex-start;
  }

  .temp {
    display: flex;
    flex-direction: column;
  }

  .table {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0%;
    justify-content: center;
  }

  .table a {
    text-decoration: none;
    color: purple;
  }

  .table-cell {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    text-align: center;
    height: 10rem;
  }

  .table-cell.dark {
    background-color: rgb(200, 50, 120);
  }

  .table-row {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    padding: 10px;
    text-decoration: none;
  }

  .card-content {
    flex-grow: 1;
  }

  .arrow {
    position: absolute;
    top: 5px;
    right: 5px;
    opacity: 0.6;
  }

  .left-wing {
    position: absolute;
    bottom: 10px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
  }

  .breed-name {
    font-size: 1.4rem;
  }

  .feature {
    font-size: small;
  }

  .load {
    display: flex;
    justify-content: center;
    align-items: center;
    color: aliceblue;
    font-size: 12vw;
  }
}
